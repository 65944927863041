<template>
  <div class="returnSet activityDetails">
    <div>
      <div class="rightBtn">
        <div style="display: flex">
          <div v-for="(item, index) in linst" :key="index" class="defaultStyle"
            :class="codeStyle === index ? 'opp' : ''" @click="add(item, index)">
            {{ item }}
          </div>
        </div>
      </div>
      <div class="bottom">
        <div v-show="codeStyle == 0" class="hesed">
          <div class="type">
            <div class="error" v-if="item.re_status == 1"><img src="@/assets/img/audit/wait.png" alt="">
             待审核
            </div>
            <div class="error" v-if="item.re_status == 2">
              <img src="@/assets/img/audit/pass.png" alt="">审核通过
            </div>
            <div class="error" v-if="item.re_status == 3">
              <img src="@/assets/img/audit/refuse.png" alt="">
              <div class="error-cause">审核驳回<span> 驳回原因：{{ item.refuse_reason }}
                </span></div> 
            </div>
          </div>
          <!-- <div class="title">基础资料</div> -->
          <div style="margin-top: 20px" class="basics">
            <!-- <div>商品类型&emsp;{{}}</div> -->
            <div><span>商品名称</span>{{ item.goods_name }}</div>
            <div><span>简称</span>{{ item.common_name }}</div>
            <div><span>生产厂家</span>{{ item.manufacturer }}</div>
            <div><span>规格</span>{{ item.specification }}</div>
            <div><span>单位</span>{{ item.unit }}</div>
            <div><span>零售价</span>{{ item.market_price }}</div>
            <div><span>原价</span>{{ item.settlement_price }}</div>
            <div><span>销售价</span>{{ item.selling_price }}</div>
            <div><span>库存</span>{{ item.quantity }}</div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div v-show="codeStyle == 1" class="hesed">
          <div class="title">赠品图片</div>
          <div style="margin-top: -20px">
            <el-image
              v-for="(value, i) in item.images"
              :key="i"
              :preview-src-list="[value.picture_url]"
              class="image"
              :src="value.picture_url"
              fit="scale-down"
            />
            <div style="margin: 20px 0px 0px 10px">
              <div>
                ※温馨提示：
                1、图片上传会进行人工审核，请勿上传与商品无关的图片；
              </div>
              <div style="margin-left: 87px">
                <div>
                  2、上传图片支持.png、.jpg图片格式，最多支持上传6张图片；
                </div>
                <div>
                  3、商品图片长宽比例为1:1，上传其他比例图片可能导致展示时拉伸、压缩等图片变形情况；
                </div>
                <div>
                  4、图片尺寸建议1080*1080；文件大小建议控制在5MB以内，不得超过10MB；
                </div>
                <div>
                  5、从左至右第一张图为主图，主图将用于购物车、订单、商品列表等页面展示；
                </div>
                <div>6、长按鼠标左右拖动图片可以调整图片顺序。</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div v-show="codeStyle == 2" class="hesed">
          <div class="title">详细信息</div>
          <div style="margin-top: -20px" class="text-area" >
            <pre><div v-html="item.content" class="ql-editor"></div></pre>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-box">
      <div class="btn-2" @click="$router.go(-1)">返回</div>
      <div class="btn-3" @click="toRef" v-if="type == 'audit'">审核驳回</div>
      <div class="btn-1" @click="toNext" v-if="type == 'audit'">审核通过</div>
    </div>
    <!-- 审核驳回提醒弹窗 -->
    <el-dialog title="审核驳回提醒" :visible.sync="dialogVisible" width="400px">
      <div class="tsed">
        确定驳回当前活动商品？如果当前活动商品某些信息不符合国家网络相关法律要求，请在驳回原因中注明。
      </div>
      <div class="fx">
        <span style="flex: 2">驳回原因：</span>
        <el-input
          style="flex: 7"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 3 }"
          maxlength="200"
          placeholder="请输入内容"
          v-model="textarea"
          show-word-limit
        >
        </el-input>
      </div>
      <div slot="footer" class="text-center">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="assdistribued">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex");
const { mapState, mapActions } = createNamespacedHelpers("Audit"); //vuex公共库
export default {
  name: "eventitemdetails",
  watch: {
    // $route(to, from) {
    //   if (to.name == "eventitemdetails") {
    //     this.getdata();
    //   }
    // },
  },
  data() {
    return {
      activeName: "Comminformation",
      item: {},
      dialogVisible: false, //审核驳回弹窗
      textarea: "", //驳回原因
      type: this.$route.query.type,
            linst: ["商品资料", "商品图片", "详细信息"],
      codeStyle: 0, // 默认选项
    };
  },
  created() {
    this.getdata();
  },
  methods: {
    ...mapActions(["postactivityReview",'getactivityReviewInfo']),
    async getdata() {
      let id = this.$route.query.id;
      let res=await this.getactivityReviewInfo({ id: id })
        this.item = res.data;
      
      // this.type = this.$route.query.type;
    },
     // 点击筛选
    add(item, index) {
      this.itemdata = item;
      this.codeStyle = index;
      // if (item == "商品资料") {
      //   this.searchData.type = 0;
      // } else if (item == "商品图片") {
      //   this.searchData.type = 1;
      // } else if (item == "详细信息") {
      //   this.searchData.type = 2;
      // }
      // this.reset();
      // this.searchBusinessList();
    },
    //审核驳回弹窗
    toRef() {
      this.dialogVisible = true;
    },
    //审核驳回
    assdistribued() {
      this.reviewApi(3);
    },
    // 审核接口
    async reviewApi(val) {
      let params = {
        id: this.$route.query.id,
        re_status: val,
      };
      if (val == 3) {
        params.refuse_reason = this.textarea;
      }
      let res = await this.postactivityReview(params);
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: "审核通过!",
        });
        this.$router.push({
          path: "/activityAudit",
        });
      } else if (res.code == 90000) {
        this.$alert(
          "您审核的当前活动商品信息已重新提交，点击确定返回列表，请已新的信息为准进行审核",
          "活动商品变动提醒",
          {
            confirmButtonText: "确定",
            callback: (action) => {
              this.$router.go(-1);
            },
          }
        );
      }
    },
    //审核通过
    toNext() {
      this.$confirm(
        "确定通过当前活动商品？请您仔细核对活动商品信息，以保证当前活动商品信息符合国家网络相关法律要求。",
        "审核通过提醒",
        {
          confirmButtonText: "审核通过",
          cancelButtonText: "取消",
        }
      ).then(() => {
        this.reviewApi(2);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.returnSet {
  padding: 20px;
  // margin-left: 20px;
  background-color: #fff;
  border-radius: 8px;
  .top1 {
    width: 300px;
  }
}
.defaultStyle {
  width: 100px;
  padding: 10px 20px;
  text-align: center;
  line-height: 100%;
  font-size: 14px;
  cursor: pointer;
}
.opp {
  color: #06B7AE;
  border-radius: 5px;
  font-weight: bold;
  background-color: rgba($color: #2E3238, $alpha: 0.05);
}
.bottom {
  text-align: left;
  margin-left: 20px;
  color: #666;
  font-size: 14px;
  margin-top: 20px;
  line-height: 30px;
  .type {
    margin-bottom: 20px;
  }
  .error {
    display: flex;
    line-height: 40px;
    font-weight: bold;
    align-items: center;
    width: 100%;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 10px;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    
  }
  .error-cause {
    // text-indent: 48px; 
    display: flex;
    flex-direction: column;
    // align-items: center;
    line-height: 20px;
    justify-content: center;
    // padding: 20px 0;
    height: 40px;
  }
  .hesed {
    height: 650px;
  }
  .title {
    width: 100%;
    font-weight: bold;
    padding-bottom: 5px;
    border-bottom: dashed 1px #ddd;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
}
.top2 {
  height: 40px;
}
.minBottom {
  overflow: hidden;
  font-size: 14px;
  color: #666;
  margin-top: 20px;
  .bottomTitle {
    width: 100%;
    font-size: 14px;
    color: #000;
  }
  .bottomTitled {
    width: 100%;
    font-size: 14px;
    margin-top: 10px;
    color: #000;
  }
}
.bored {
  border: 1px solid #666;
  margin-top: 5px;
  padding: 10px;
  line-height: 30px;
}
.fusty {
  color: #666;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: -10px;
}
.showed {
  max-height: 430px;
  overflow: auto;
}
.shanpin {
  height: 650px;
  overflow-y: auto;
  padding: 0px 10px 10px 0px;
  margin-bottom: 40px;
}
.tsed {
  margin-bottom: 20px;
  line-height: 20px;
}
.image {
  width: 100px;
  height: 100px;
  margin-right: 10px;
}
.text-area{
  width: 100%;
  height: 100%;
}
.ql-editor{
  height: 600px;
  overflow-y: scroll;
}
.basics{
  div{
    // display: flex;
    // justify-content: center;
    width: 100%;
    color: #333;
    span{
      width: 48%;
      text-align: end;
      display: inline-block;
      margin-right: 2%;
      color: #666;
    }
  }
}
.btn-box{
  height: 32px;
  font-size: 14px;
  font-weight: normal;
  .btn-2{
    height: 32px;
    line-height: 32px;
    padding: 0;
    width: 88px;
    text-align: center;
    background-color: #fff;
    color: #1C1F23 ;
    border: 1px solid #D4D7D9;
  }
  .btn-1{
    height: 32px;
    line-height: 32px;
    padding: 0;
    width: 116px;
    text-align: center;
    background-color: #06B7AE;
  }
  .btn-3{
    height: 32px;
    line-height: 32px;
    padding: 0;
    width: 116px;
    text-align: center;
    background-color: #F6B142;
  }
}
</style>
